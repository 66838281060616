import { useState, useEffect, useRef } from 'react';
import { Box, InputBase, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TypedGridColTypeDef, TypedGridRenderEditCellParams } from '../typed-grid';
import { experimental_sx as sx } from '@mui/system';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import '@mui/lab';
import useMergedRef from '@react-hook/merged-ref';
import moment from 'moment';
import { EditCellContainer, cellStyles } from './utils';

export const DateInputEditCellStyled = styled(InputBase)(
    sx({
        typography: 'body2',
		"& .MuiInputAdornment-positionEnd": {
			marginLeft: '-4px',
			'& .MuiIconButton-edgeEnd' : {
				padding: '4px',
				marginRight: '0',
				"& .MuiSvgIcon-root": {
					height: '16px',
					width: '16px',
				},
			},
		},
    }),
);

export const DateEditCellInputRender = ({ inputRef, inputProps, InputProps, ref }: TextFieldProps & { ref: React.MutableRefObject<HTMLInputElement | null>; }) => {
	//if the end adornment should be hidden
	const [hidden, setHidden] = useState<boolean>(true);

	return (
		<DateInputEditCellStyled
			fullWidth
			{...InputProps}
			endAdornment={hidden || InputProps?.endAdornment === undefined ? undefined : <Box>{InputProps.endAdornment}</Box>}
			onMouseEnter={() => setHidden(false)}
			onMouseLeave={() => setHidden(true)}
			inputProps={inputProps}
			inputRef={useMergedRef(inputRef as React.MutableRefObject<HTMLInputElement>, ref)}
		/>
	);
};

interface GetDateColProps<T extends boolean | undefined> extends Omit<DatePickerProps<Date, Date>, "id" | "value" | "onChange" | "renderInput" | "ref"> {
	required?: T;
}

type TEditCellParamsDateValue = TypedGridRenderEditCellParams<{ id: string | number; value: Date; }, { value: Date; }, never, "value", "cell">;
type TEditCellParamsDateValueNullable = TypedGridRenderEditCellParams<{ id: string | number; value: Date | null; }, { value: Date | null; }, never, "value", "cell">;

export type DateEditCellProps<T extends boolean | undefined, TEditCellParams extends {} = {}> = 
	TEditCellParams extends never ?
		T extends true ? 
			GetDateColProps<T> & TEditCellParamsDateValue
			: GetDateColProps<T> & TEditCellParamsDateValueNullable
	: GetDateColProps<T> & TEditCellParams

export const DateEditCell = <T extends boolean | undefined = undefined>(props: DateEditCellProps<T>) => {
    const { classes, cx } = cellStyles();
	
	//get DateEditCell props
	const { required } = (props as DateEditCellProps<T, TEditCellParamsDateValueNullable>);

    //get GridRenderEditCellParams
    const { id, value, field, api, hasFocus } = (props as DateEditCellProps<T, TEditCellParamsDateValueNullable>);

	//input ref
    const ref = useRef<HTMLInputElement | null>(null);

    //if hasFocus is true focus this element
    useEffect(() => { if (hasFocus) ref.current?.focus(); }, [hasFocus]);

    return (
		<EditCellContainer required={required && !value}>
			<DatePicker
				value={value ? moment(value) : null}
				onChange={(value) => {
					//dont update with null if the field is required
					if (required) {
						if (!value) return;
						api.setEditCellValue({ id, field, value: value.toDate() } as any);
					} else {
						api.setEditCellValue({ id, field, value: value?.toDate() ?? null } as any);
					}
				}}
				disableMaskedInput
				inputFormat={"M/D/YYYY"}
				renderInput={({ inputProps, InputProps, ...props }) => (
					<DateEditCellInputRender 
						{...props} 
						ref={ref}
						inputProps={{
							...inputProps,
							placeholder: "mm/dd/yyyy",
						}}
						InputProps={{
							...InputProps,
							className: cx(InputProps?.className, { 
								[classes.warning]: value instanceof Date && isNaN(value.getTime()),
							}),
						}}
					/>
				)}
				{...(props as {})}
			/>
		</EditCellContainer>
    );
};

export const DATE_COL: TypedGridColTypeDef<Date>  = {
    type: 'date',
	valueGetter: ({ field, row }) => {
		const value = (row as any)[field];
		if (value instanceof Date) return value;
		if (!value) return undefined as unknown as Date; 
		return moment(value).toDate();
	},
	valueFormatter: ({ value }) => moment(value).format("MM/DD/YYYY"), 
    renderEditCell: (props) => <DateEditCell required {...props}/>,
};

export const NULLABLE_DATE_COL: TypedGridColTypeDef<Date | null>  = {
    type: 'date',
	valueGetter: ({ field, row }) => {
		const value = (row as any)[field];
		if (value instanceof Date) return value;
		return value ? moment(value).toDate() : null;
	},
	valueFormatter: ({ value }) => value ? moment(value).format("MM/DD/YYYY") : "", 
    renderEditCell: (props) => <DateEditCell required={false} {...props}/>,
};

export const getDateColDef = <
	ValueNullable extends boolean = false
>({ required, ...props }: GetDateColProps<ValueNullable>): TypedGridColTypeDef<ValueNullable extends true ? number : number | null> => ({
	type: 'number',
	renderCell: ({ value }) => value instanceof Date ? '' : moment(value).format("MM/DD/YYYY"),
	renderEditCell: (renderProps) => <DateEditCell required={required} {...props} {...renderProps as any}/>,
});

//v6 col def
export const NULLABLE_DATE_COL_V6 = {
	type: 'date',
	valueGetter: ({ field, row }: any) => {
		const value = (row as any)[field];
		if (value instanceof Date) return value;
		return value ? moment(value).toDate() : null;
	},
	valueFormatter: ({ value }: any) => value ? moment(value).format("MM/DD/YYYY") : "", 
    renderEditCell: (props: any) => <DateEditCell required={false} {...props}/>
};

//v6 col def
export const DATE_COL_V6 = {
	type: 'date',
	valueGetter: ({ field, row }: any) => {
		const value = (row as any)[field];
		if (value instanceof Date) return value;
		if (!value) return undefined as unknown as Date; 
		return moment(value).toDate();
	},
	valueFormatter: ({ value }: any) => moment(value).format("MM/DD/YYYY"), 
    renderEditCell: (props: any) => <DateEditCell {...props}/>
};