import React, { useMemo, useState } from 'react';
import { AppBar, Paper, Popper, Button, IconButton, Toolbar, Typography, ClickAwayListener, Drawer, Divider, Link as ExternalLink, Backdrop } from '@mui/material';
import { Link } from 'react-router-dom';
import DynamicToolbar from './DynamicToolbar/DynamicToolbar';
import topNavigationStyles from './TopNavigationStyles';
import DynamicSubNavigation from './DynamicSubNavigation/DynamicSubNavigation';
import DynamicSideNavigation from './DynamicSideNavigation/DynamicSideNavigation';
import { Alignment } from '@imas/components/layout';
import { getUserData } from '@imas/redux';
import { IMAS_MENU, SUB_NAVIGATION_MENU } from '@imas/constants/menus';
import { VersionHistory } from '@imas/components/misc';
import { Menu as MenuIcon, AccountCircle as AccountCircleIcon, AttachMoney as CashIcon, Person as PersonIcon, Info as InfoIcon, Chat as ChatIcon, Settings as SettingsIcon, Logout as LogoutIcon, WorkHistoryOutlined as WorkHistoryIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { Island as IslandIcon, CalendarClock as CalendarClockIcon } from 'mdi-material-ui';
import { useSelector } from 'react-redux';
import { getUserRole } from '@imas/utils/user';
import VersionHistoryDialog from '@imas/components/misc/VersionHistory/VersionHistoryDialog/VersionHistoryDialog';

/** Handles Navigation throughout app for desktop & mobile. */
export const TopNavigation = () => {
    //component styling 
    const { classes, deviceInfo } = topNavigationStyles();

    //redux store variables
    const userData = useSelector(getUserData);  

    /**
     *  Component State Variables:
     *      anchorEl - Currently selected element for the popover menu to appear under.
     *      selectedMenuIndex - The index of the currently selected menu.
    */
    const [anchorEl, setAnchorEl] = useState<EventTarget & Element | null>(null);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);


    //handles opening the menu
    const openMenu = (event: React.UIEvent) => {
        //change the anchorEl if it has changed
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    //handles closing the menu
    const closeMenu = () => {
        setAnchorEl(null);
    };

    //filter menu contents based on user role
    const filteredMenu = useMemo(() => {
        //get user role
        const userRole = getUserRole(userData);
		
        //get all menu options where the required role is less than or the same as the user's current role, then remove any menus with 0 options
        return IMAS_MENU.map(x => ({ ...x, options: x.options.filter(y => (y.requiredRole <= userRole) && (!deviceInfo.isMd ? true : !y.mobileOnly)) })).filter(x => x.options.length > 0);
    }, [userData, deviceInfo]);

    //filtered menu for subnavigation purposes. It adds the user category for their subnavigation
    const subNavigationMenu = useMemo(() => {
        //get user role
        const userRole = getUserRole(userData);
		
        //get all menu options where the required role is less than or the same as the user's current role, then remove any menus with 0 options
        return SUB_NAVIGATION_MENU.map(x => ({ ...x, options: x.options.filter(y => (y.requiredRole <= userRole) && (!deviceInfo.isMd ? true : !y.mobileOnly)) })).filter(x => x.options.length > 0);
    }, [userData, deviceInfo]);

    //dont display header if employeeData isn't loaded
    if (userData === null) return null;

    return (
        <div className={classes.topNavContainer}>
            <AppBar position={"static"} color={"primary"}>
                <Toolbar className={deviceInfo.isMd ? classes.desktopToolbar : undefined}>
                    {/* If the device is small display the sidebar open button, if the device is large (desktop) dynamically generate the Menu layout */}
                    {!deviceInfo.isMd ? (
                        <IconButton
                            className={classes.navBarIcon}
                            onClick={() => { setSidebarOpen(true); }}
                            size={"medium"}
                        ><MenuIcon/></IconButton>
                    ) : (
                        <DynamicToolbar menu={filteredMenu}/>
                    )}
                    
                    {/* Top-Right employee name and profile dropdown */}
                    <div className={classes.employeeInfoContainer}>
                        <Typography className={classes.employeeName} color="textPrimary" variant={"subtitle1"}>{userData === null ? "" : userData.employee.prefFirstName !== null ? userData.employee.prefFirstName : userData.employee.prefFirstName}</Typography>
                        
                        {/* Profile Dropdown Menu Button */}
                        <IconButton
                            className={classes.navBarIcon}
                            onClick={(event) => openMenu(event)}
                            onMouseOver={(event) => openMenu(event)}
                            size={"small"}
                        ><AccountCircleIcon/></IconButton>

                        {/* Profile Dropdown Menu Popper */}
                        <Backdrop open={anchorEl !== null && !deviceInfo.isSm}/>
                        <Popper 
                            id={"employee-dropdown"} 
                            open={anchorEl !== null}
                            style={{zIndex: 1150}} 
                            placement={"bottom-end"}
                            anchorEl={anchorEl}
                            onMouseLeave={closeMenu}
                        >
                            <ClickAwayListener onClickAway={closeMenu}>
                                {/* Profile Dropdown Menu */}
                                <Paper className={classes.dropdownContainer}>
                                    <Button variant={"text"} color={"inherit"} startIcon={<PersonIcon/>} onClick={closeMenu} className={classes.dropdownButton} component={Link} to="/user/profile">{"My Profile"}</Button>
                                    <Button variant={"text"} color={"inherit"} startIcon={<IslandIcon/>} onClick={closeMenu} className={classes.dropdownButton} component={Link} to="/user/time-off">{"Time Off"}</Button>
                                    <Button variant={"text"} color={"inherit"} startIcon={<CalendarClockIcon/>} onClick={closeMenu} className={classes.dropdownButton} component={Link} to="/user/time-sheets">{"Hours Worked"}</Button>
                                    <Button variant={"text"} color={"inherit"} startIcon={<CashIcon/>} onClick={closeMenu} className={classes.dropdownButton} component={Link} to="/user/expense-reports">{"Expense Reports"}</Button>
                                    <Button variant={"text"} color={"inherit"} startIcon={<AccessTimeIcon/>} onClick={closeMenu} className={classes.dropdownButton} component={Link} to="/user/work-times">{"Work Times"}</Button>

                                    <Divider sx={{ marginTop: 1, marginBottom: 1 }}/>
                                    <Button variant={"text"} color={"inherit"} startIcon={<InfoIcon/>} onClick={() => {closeMenu; setDialogOpen(true);}} className={classes.dropdownButton}>{"About IMAS"}</Button>
                                    <Button variant={"text"} color={"inherit"} startIcon={<SettingsIcon/>} onClick={closeMenu} className={classes.dropdownButton} component={Link} to="/user/settings">{"Settings"}</Button>
                                    <Button variant={"text"} color={"inherit"} startIcon={<ChatIcon/>} onClick={closeMenu} className={classes.dropdownButton} component={Link} to="/user/feedback">{"Give Feedback"}</Button>
                                    <Button variant={"text"} color={"inherit"} startIcon={<LogoutIcon/>} onClick={closeMenu} className={classes.dropdownButton} component={Link} to="/logout">{"Log Out"}</Button>
                                </Paper>
                            </ClickAwayListener>
                        </Popper>
                    </div>
                </Toolbar>
            </AppBar>

            {/* If the device is small display the DynamicSideNavigation, if it is large then display the DynamicSubNavigation */}
            {!deviceInfo.isMd ? (
                <Drawer open={sidebarOpen} onClose={() => setSidebarOpen(false)} className={classes.drawer}>
                    <Alignment column className={classes.drawerColumn}>
                        <DynamicSideNavigation menu={filteredMenu} closeNav={() => setSidebarOpen(false)}/>
                        
                        {/* Copyright & Version at bottom */}
                        <Alignment column className={classes.drawerFooter}>
                            {/* Version */}
                            <VersionHistory
                                className={classes.version}
                                linkProps={{ variant: "body1", color: "textPrimary" }}
                            />

                            {/* Copyright */}
                            <ExternalLink
                                color={"inherit"}
                                href={"https://pnltest.com/"}
                                variant={"body1"}
                            >&#169;{new Date().getFullYear()} - PNL</ExternalLink>
                        </Alignment>
                    </Alignment>
                </Drawer>
            ) :

            // only show sub nav if the device is tall enough
            deviceInfo.isTall ? (
                <Toolbar className={classes.subNav}>
                    <DynamicSubNavigation menu={subNavigationMenu}/>
                </Toolbar>
            ) : null}

			{/* Show Dialog if dialogOpen is true */}
			{dialogOpen ? (
                <VersionHistoryDialog onClose={() => setDialogOpen(false)}/>
            ) : null}
        </div>
    );
};