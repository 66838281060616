import { axiosData, axiosNullableData, objectToFormData, SourceGetter } from "@imas/api";
import axios from 'axios';
import Email from 'src/api/types/api/Email';

import { Applicant, ApplicantForm, vApplicant, vApplicantDocument, vApplicantPayRateTemplate, PayRateTemplateDetail, PayRateTemplateBaseAmmount, PayRateTemplateOffer } from "./types";
import { JsonFile } from "src/api/types/api/JsonFile";
const APPLICANT_URL = "/api/applicants";

export const GetApplicants = (getSource: SourceGetter) => {
    return async (): Promise<Applicant[]> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}`,
            cancelToken: getSource().token
        });
    };
};

export const GetApplicant = (getSource: SourceGetter) => {
    return async (id: int): Promise<Applicant> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/${id}`,
            cancelToken: getSource().token,
        });
    };
};

export const GetOfferSentByAbbrev = (getSource: SourceGetter) => {
    return async (id: int): Promise<string> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/abbrev/${id}`,
            cancelToken: getSource().token,
        });
    };
};

export const GetvApplicants = (getSource: SourceGetter) => {
    return async (): Promise<vApplicant[]> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/v-applicants`,
            cancelToken: getSource().token
        });
    };
};
export const GetvApplicant = (getSource: SourceGetter) => {
    return async (id: int): Promise<vApplicant> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/vApplicant/${id}`,
            cancelToken: getSource().token,
        });
    };
};

export const GetApplicantDocuments = (getSource: SourceGetter) => {
    return async (id: int, name: string): Promise<vApplicantDocument[]> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/document/${id}/${name}`,
            cancelToken: getSource().token
        });
    };
};

export const GetvApplicantPayRateTemplateDetail = (getSource: SourceGetter) => {
    return async (id: int): Promise<vApplicantPayRateTemplate[]> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/vApplicantPayRateTemplateDetail/${id}`,
            cancelToken: getSource().token
        });
    };
};


export const GetTitles = (getSource: SourceGetter) => {
    return async (): Promise<string[]> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/titles`,
            cancelToken: getSource().token
        });
    };
};

export const CreateApplicant = (getSource: SourceGetter) => {
    return async (userid: int, data: ApplicantForm): Promise<Applicant> => {
        return axiosData({
            method: 'post',
            url: `${APPLICANT_URL}/${userid}`,
            data: objectToFormData(data),
            headers: { "Content-Type": "multipart/form-data"},
            cancelToken: getSource().token,
        });
    };
};

export const UpdateApplicant = (getSource: SourceGetter) => {
    return async (id: int, data: ApplicantForm): Promise<Applicant> => {
        return axiosData({
            method: 'put',
            url:`${APPLICANT_URL}/${id}`,
            data: objectToFormData(data),
            headers: { "Content-Type": "multipart/form-data"},
            cancelToken: getSource().token,
        });
    };
};

export const UploadApplicantAttachments = (getSource: SourceGetter) => {
    return async (uniqueId: int, attachments: File[], foldername: string): Promise<boolean> => {
        return axiosData({
			method: 'post',
			url: `${APPLICANT_URL}/documents/${foldername}`,
			data: objectToFormData({ uniqueId, files: attachments }),
			headers: { "Content-Type": "multipart/form-data" },
			cancelToken: getSource().token,
		});
    };

};


export const UploadOffer = (getSource: SourceGetter) => {
    return async (uniqueId: int, attachments: File[]): Promise<boolean> => {
        return axiosData({
			method: 'post',
			url: `${APPLICANT_URL}/offer`,
			data: objectToFormData({ uniqueId, files: attachments }),
			headers: { "Content-Type": "multipart/form-data" },
			cancelToken: getSource().token,
		});
    };

};

export const UploadResume = (getSource: SourceGetter) => {
    return async (uniqueId: int, attachments: File[]): Promise<boolean> => {
        return axiosData({
			method: 'post',
			url: `${APPLICANT_URL}/resume`,
			data: objectToFormData({ uniqueId, files: attachments }),
			headers: { "Content-Type": "multipart/form-data" },
			cancelToken: getSource().token,
		});
    };

};


export const UpdateWageMatrix = (getSource: SourceGetter) => {
    return async (offer: vApplicantPayRateTemplate): Promise<vApplicantPayRateTemplate> => {
        return await axiosData({
            method: 'put',
            url: `${APPLICANT_URL}/wageMatrix`,
            data: offer,
            cancelToken: getSource().token
        });
    };
};

export const UpdateApplicantWageMatrix = (getSource: SourceGetter) => {
    return async (appID: int, userID: int | null, payRate: decimal | null, expectedHireDate: Date | null, offerdescription:string | null,  offerbenefits:string | null, offerfooter:string | null, email:string | null, payratetemplateofferID: int | null) => {
        return await axiosData({
            method: 'put',
            url: `${APPLICANT_URL}/applicantWageMatrix`,
            params: {
                id: appID,
                userID: userID,
            },
            data: objectToFormData({ payRate, expectedHireDate, offerdescription, offerbenefits, offerfooter, email, payratetemplateofferID }),
            cancelToken: getSource().token
        });
    };
   
};

export const GetPayRateTemplateDetail = (getSource: SourceGetter) => {
    return async (): Promise<PayRateTemplateDetail[]> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/payRateTemplate`,
            cancelToken: getSource().token
        });
    };
};

export const GetPayRateTemplateBaseAmount = (getSource: SourceGetter) => {
    return async (): Promise<PayRateTemplateBaseAmmount[]> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/payratebaseamt`,
            cancelToken: getSource().token
        });
    };
};

export const UpdatePayRateTemplateBaseAmount = (getSource: SourceGetter) => {
    return async (BaseAmount: PayRateTemplateBaseAmmount): Promise<PayRateTemplateBaseAmmount> => {
        return axiosData({
            method: 'put',
            url: `${APPLICANT_URL}/payratebaseamt`,
            data: BaseAmount,
            cancelToken: getSource().token
        });
    };
};

export const CreatePayRateTemplateBaseAmount = (getSource: SourceGetter) => {
    return async (BaseAmount: PayRateTemplateBaseAmmount): Promise<PayRateTemplateBaseAmmount> => {
        return axiosData({
            method: 'post',
            url: `${APPLICANT_URL}/payratebaseamt`,
            data: BaseAmount,
            cancelToken: getSource().token
        });
    };
};

export const DeletePayRateTemplateBaseAmount = (getSource: SourceGetter) => {
    return async (id: int): Promise<PayRateTemplateBaseAmmount[]> => {
        return axiosData({
            method: 'delete',
            url: `${APPLICANT_URL}/payratebaseamt/${id}`,
            cancelToken: getSource().token
        });
    };
};

export const GetApplicantBaseAmtID = (getSource: SourceGetter) => {
    return async (): Promise<int[]> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/distinctPrtbaID`,
            cancelToken: getSource().token
        });
    };
};

export const PayRateTemplateUpdate = (getSource: SourceGetter) => {
    return async (appId: int, id: int | null): Promise<boolean> => {
        return axiosData({
            method: 'post',
            url: `${APPLICANT_URL}/payRateTemplateUpdate/${appId}/${id ?? -1}`,
            cancelToken: getSource().token
        });
    };
};


export const WageMatrixPDF = (getSource: SourceGetter) => {
    return async (appId: int): Promise<boolean> => {
        return axiosData({
            method: 'post',
            url: `${APPLICANT_URL}/wageMatrixPDF/${appId}`,
            cancelToken: getSource().token

        });    
    };
};

export const GetPayRateTemplateOffers = (getSource: SourceGetter) => {
    return async (): Promise<PayRateTemplateOffer[]> => {
        return axiosData({
            method: 'get',
            url: `${APPLICANT_URL}/payRateTemplateOffers`,
            cancelToken: getSource().token
        });
    };
};

export const ApplicantSign = (getSource: SourceGetter) => {
    return  (Id: int, file: File, ): Promise<boolean> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<boolean>((resolve, reject) => {
            axios({
                method: 'put',
                url: `${APPLICANT_URL}/sign`,
				data: objectToFormData({ Id:Id, file: file }),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

export const EmailOffer = (getSource: SourceGetter) => {
    return async (applicantId: int, encodeedId: string, email: Email): Promise<boolean> => {
        return axiosData({
            method: 'post',
            url: `${APPLICANT_URL}/emailOffer/${applicantId}/${encodeedId}`,
            data: email,
            cancelToken: getSource().token
        });
    };
};

export const Rehire = (getSource: SourceGetter) => {
    return async (empId: int, editorId: int, file: File): Promise<int> => {
        console.log(file);
        return axiosData({
            method: 'post',
            url: `${APPLICANT_URL}/rehire/${empId}/${editorId}`,
            data: objectToFormData({ Id:editorId, file: file }),

            cancelToken: getSource().token
        });
    };
};
